import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_byoc_filter = _resolveComponent("services-byoc-filter")!
  const _component_services_byoc_table = _resolveComponent("services-byoc-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_services_byoc_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        "search-placeholder": "Search providers",
        class: "mb-4"
      }, null, 8, ["search"]),
      _createVNode(_component_services_byoc_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "show-selection": false,
        "items-name": "providers",
        "show-link": ""
      }, null, 8, ["headers", "items"])
    ]),
    _: 3
  }))
}