
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ServicesByocFilter from '@/components/pages/services/byoc/list/ServicesByocFilter.vue'
import ServicesByocTable from '@/components/pages/services/byoc/list/ServicesByocTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { ProviderListMock } from '@/definitions/services/byoc/data'

export default defineComponent({
  components: {
    ServicesByocFilter,
    PageContent,
    ServicesByocTable,
  },
  setup() {
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Provider', value: 'provider-slot' },
      { text: 'Linked numbers', value: 'linkedNumbers' },
      { text: 'Account ID', value: 'accountId' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date added', value: 'date' },
    ])
    const tableItems = ref(ProviderListMock)

    return {
      search,
      tableHeaders,
      tableItems,
    }
  },
})
